@font-face {
  font-family: 'ff-dax-compact-pro';
  src: local('ff-dax-compact-pro'), url(./assets/Fonts/Dax.ttf) format('truetype');
}

@font-face {
  font-family: 'ff-dax-compact-pro';
  src: local('ff-dax-compact-pro'), url(./assets//Fonts/dax-bold\ 2.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'Avenir Book';
  src: local('Avenir Book'), url(./assets/Fonts/Avenir.ttc) format('truetype');
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  width: 100%;
  height: 100%;
  overflow-x: hidden; 
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0px;
  padding: 0px;
  position: relative;
  padding-bottom: 8rem;
  min-height: 100%;
  font-family: 'Avenir Book', sans-serif;
}

.dax-font {
  font-family: 'ff-dax-compact-pro', sans-serif;
}

header.main-header {
  background: no-repeat center center;
  background-attachment: scroll;
  position: relative;
  background-size: cover;
  height: 100vh;
  max-height: 625px;
}
  
header.main-header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(30, 53, 140, 0.8);
  padding-top: 15em;
}
 
header.main-header .contact-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(2, 2, 2, 0.3);
  height: 65%;
  max-height: 625px;
  padding-top: 10em;
}

header.main-header .faq-overlay {
  position: absolute;
  top: 0;
  left: 0; 
  padding-top: 15em;
  background-color: rgb(2, 2, 2, 0.3);
}

header.main-header .about-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(2, 2, 2, 0.3);
  height: 65%;
  max-height: 625px;
  padding-top: 10em;
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

.margin-top-fixed {
    margin-top: 4em;
}

.padding-top-fixed {
    padding-top: 4em;
}

.padding-top-6 {
  padding-top: 6em;
}

.text-sub-color {
    color : #5c7eff;
}

.border-label-bottom { 
  border: 2px solid #5c7eff;
  width: 4rem;
}

a.nav-item:not(.active) {
  border-bottom: 3px solid lightgrey;  
  border-radius: 0 !important;
  color: #1e358c !important;
}
a.nav-item.active {
  border-bottom: 3px solid #5c7eff;  
  border-radius: 0 !important;
  color: #1e358c !important;
  background-color: transparent !important;
}

a.nav-link:not(.active) {
  color: #1e358c !important;
}

a.nav-link.active {
  color: #5c7eff !important;
}

.padding-both-ends-4 {
  padding: 0 4em 0 4em;
}

.custom-hr-tag{
  margin: 0 4em 0 4em;
  border: 1px solid lightgrey;
}

.products-div{
  margin: 0 4em 0 4em;
}

.product-li {
  border: 1px solid lightgrey;
  padding: 1em;
  margin: 0 .5em;
  width: 150px;
  height: 175px;
}

.main-blue-bg {
  background-color: #1e358c;
}

.main-blue-font-color {
  color: #1e358c;
}

.sub-blue-bg {
  background-color: #5c7eff;
}

.sub-blue-font-color {
  color: #5c7eff;
}

.ordinay-font-color {
  color: #252525;
}

.coming-soon-placeholder-div {
  background-color: #fff;
  height: 250px;
  width: auto;
}

.padding-5 {
  padding: 5em;
}
.icon-caret {
  margin-left: 10em;
}
.collapse-arrow .btn.btn-link .icon-caret {
  transform: rotate(90deg);
  transition: .3s transform ease-in-out;
  display: inline-block;
}

.collapse-arrow .btn.btn-link.collapsed .icon-caret {
  transform: rotate(0deg);
}

.btn.btn-link:hover,
.btn.btn-link:focus {
  text-decoration: none;
}
.tab-pane.fade:not(.active) {
  display: none;
}

.contact-div-padding {
  padding: 3em 8em;
}

.about-div-padding {
  padding: 8em 8em;
}

.contact-div-child {
  min-height: 15em;
  padding: 3em;
}

.custom-pad-indicative-cost {
  padding: 3em 5em 0 5em;
}